/* styles.css */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.cdnfonts.com/css/eurostile-lt-pro-unicode');

body {
  font-family: 'Eurostile LT Pro Unicode', sans-serif;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #ff9900;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Ajout de styles Tailwind personnalisés sans @apply */
#google_translate_element {
  display: flex;
  align-items: center;
  border-radius: 9999px;
  padding: 0.25rem 1.5rem;
  height: 2.5rem;
  background-color: #FF9900;
  color: #111047;
  font-weight: bold;
  text-align: center;
  transition: background-color 0.3s ease;
}


#google_translate_element {
  @apply flex items-center rounded-full px-6 h-[50px] bg-[#FF9900] text-[#111047] font-extrabold tracking-wide hover:bg-[#fcd190] transition ease-in-out duration-300;
}

#google_translate_element:hover {
  background-color: #fcd190;
}

.goog-te-banner-frame {
  @apply hidden !important;
}
.goog-te-gadget-icon {
  @apply hidden !important;
}

.goog-logo-link {
  @apply hidden !important;
}

.goog-te-gadget {
  @apply text-black !important;
}

.goog-te-gadget-simple {
  @apply bg-white border border-gray-300 rounded overflow-hidden !important;
}



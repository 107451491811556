/* styles.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.loading-page {
  @apply flex flex-col items-center justify-center h-screen;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes fadeDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-down-animation {
  animation: fadeDown 0.3s ease-out;
}

.blur {
  filter: blur(5px); /* Ajustez le niveau de flou selon vos préférences */
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Container {
  background: hsla(36, 100%, 50%, 1);

  background: linear-gradient(90deg, hsla(36, 100%, 50%, 1) 0%, hsla(241, 64%, 17%, 1) 100%);

  background: -moz-linear-gradient(90deg, hsla(36, 100%, 50%, 1) 0%, hsla(241, 64%, 17%, 1) 100%);

  background: -webkit-linear-gradient(90deg, hsla(36, 100%, 50%, 1) 0%, hsla(241, 64%, 17%, 1) 100%);

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#FF9900", endColorstr="#111049", GradientType=1 );
}

/* Ajoutez ces classes pour personnaliser le widget de traduction avec Tailwind CSS */
/* Ajoutez ces classes pour personnaliser le widget de traduction avec Tailwind CSS */
#google_translate_element {
  @apply flex items-center rounded-full w-auto px-6 h-10 bg-[#FF9900] text-[#111047] font-extrabold tracking-wide hover:bg-[#fcd190] transition ease-in-out duration-300;
}

.goog-te-banner-frame {
  @apply hidden !important;
}

.goog-logo-link {
  @apply hidden !important;
}

.goog-te-gadget {
  @apply text-black !important;
}

.goog-te-gadget-simple {
  @apply bg-white border border-gray-300 rounded overflow-hidden !important;
}
 * {
  @apply transition-colors duration-300  ;
  
 }